/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "info": #f42c04,
  "secondary": #f42c04,
  // "danger": teal,
  "white": #fdfdff,
  "dark": #171123,
  // "blue": #083efd,
  // "red": #f42c04,
  // "bluesec": #a6c6f2,
  "transparent": transparent,
);
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "./waveAnimation.scss";
@import "./media-query.scss";

@import url(//fonts.googleapis.com/css?family=Lato:300:400);
@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Pushster&&family=Oxygen&Comfortaa&family=Vujahday+Script&display=swap");
html,
body,
#root {
  min-height: 100vh;
  min-width: 100vw;
}
body {
  --white: #fdfdff;
  --black: #171123;
  --blue: #083efd;
  --red: #f42c04;
  --bs-dark-rgb: rgba(0, 0, 0, 0);
  --bluesec: #a6c6f2;
  margin: 0;
  font-family: "Comfortaa", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: var(--black);
}
.header-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../public/alex-kotliarskyi-QBpZGqEMsKg-unsplash.jpg");
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header {
  text-align: center;
  // background: linear-gradient(
  //   60deg,
  //   rgba(8, 62, 253, 1) 0%,
  //   rgba(23, 17, 35, 1) 100%
  // );
  color: var(--black);
  .inner-header {
    backdrop-filter: blur(9px);
    // filter: blur(2px);
    background: rgba(255, 255, 255, 0.2);
    h1 {
      color: var(--black);
      font-weight: 600;
      font-size: 3em;
    }
  }
}
.inner-header {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 4% 10%; //3em;
  // @include desktop {
  //   padding: 5em 15em;
  // }
}
.main-header-animation {
  .mha-animate {
    animation-duration: 5s;
    animation-delay: 0.2s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
  }

  .mha-animate.glow {
    animation-name: animate-glow;
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.09, 1.02);
  }

  @keyframes animate-glow {
    0%,
    100% {
      opacity: 0;
      filter: blur(2); //brightness(3) //saturate(3);
      transform: scale(0.8, 0.8);
    }
    50% {
      opacity: 1;
      filter: blur(0); //brightness(1) saturate(1);
      transform: scale(1, 1);
    }
  }
  .disabled {
    display: none;
  }
  h1.mha-animate.glow {
    display: block;
  }
}
.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.waves {
  // width: 100%;
  // height: 30vh;
  // margin-bottom: -7px; /*Fix for safari gap*/
  // min-height: 100px;
  // max-height: 200px;
  width: 100%;
  height: 22vh;
  margin-bottom: -85px;
  min-height: 100px;
  max-height: 200px;
}
.full-container {
  padding: 0px;
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.full-container.container-fluid {
  padding-right: 1px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.social-logo {
  svg {
    fill: var(--black); // rgba(0, 0, 0, 0.55);
  }
  &:hover {
    svg {
      fill: var(--red);
    }
  }
}
.nav-social {
  width: 1.5em;
  padding: 0px;
}

.copyright {
  // padding: 0px 1em;
  // position: fixed;
  // padding-bottom: 1.2em;
  // width: 100%;
  // bottom: 0px;
  // left: 5px;
  p,
  a {
    border-radius: 5px;
    // backdrop-filter: blur(8px);
    text-decoration: none;
    line-height: 30px;
    margin: 0px;
    width: fit-content;
    color: var(--black);
    font-size: 12px;
  }
  p {
    padding: 6px;
  }
}
.download-cv {
  margin: 0px;
  .card-list {
    display: flex;
    flex-direction: row;
  }
  .row > .card:not(:last-child) {
    margin-right: 1rem;
    @include mobile {
      margin-right: 0rem;
      margin-bottom: 1rem;
    }
  }
  .card {
    // width: calc(50% - 1rem);
    border-radius: 15px;
    @include mobile {
      width: 100%;
    }
    .card-img-top {
      max-height: 20rem;
      align-self: center;
      width: fit-content;
      border-radius: 5px;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      p {
        color: var(--black);
      }
      .card-title,
      .card-text {
        color: var(--black);
        font-weight: 600;
      }
      button.dark-dl-btn {
        background: var(--white);
        color: var(--black);
        font-weight: 400;
        &:hover {
          border-color: var(--black);
          color: var(--white);
          background: var(--red);
        }
      }
    }
  }
}
.contact-section {
  // height: 100%;
  width: 100%;
  padding: 4rem 10%; // 0px 10%;
  padding: 0px 10% 4rem 10%;
  .title,
  .email,
  .social,
  .cv {
    margin-bottom: 1em;
  }
  // .social {
  //   justify-content: space-between;
  // }
  .contact-content {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(9px);
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    h1.flashy-title {
      text-align: center;
      color: var(--white);
      font-family: "Pushster";
      span {
        color: var(--red);
        font-family: "Monoton";
        opacity: 1;
        animation: opacity-changer 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          infinite;
      }
    }
    h2 {
      color: var(--black);
      font-family: "Lato";
      font-weight: 900;
      strong {
        color: var(--black);
      }
    }
  }
  .social {
    display: flex;
    flex-direction: row;
    .nav-link {
      padding: 0px;
      min-width: 50px;
    }
  }
}
.about-section {
  // background: linear-gradient(
  //   60deg,
  //   rgba(8, 62, 253, 1) 0%,
  //   rgba(23, 17, 35, 1) 100%
  // );
  // min-height: 100%;
  // display: flex;
  // justify-content: center;
  width: 100%;
  // height: 100%;
  // align-items: center;
  .about-content {
    padding: 4rem 10%; // 0px 10%;
    // padding-top: 4rem;
    .about-text {
      padding: 15px;
      background: rgba(255, 255, 255, 0.4);
      margin-bottom: 1rem;
      backdrop-filter: blur(9px);
      border-radius: 10px;
      p {
        text-align: justify;
      }
    }
  }
  h4 {
    color: var(--white);
  }
}
.white-text {
  color: var(--white);
}
p.white-text {
  font-size: 18px;
}
.black-text {
  color: var(--black);
}
p.black-text {
  font-size: 18px;
  font-weight: 600;
}
@keyframes opacity-changer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// @keyframes nav-hovered {
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     // opacity: 0;
//     transform: scale(1.5);
//   }
//   100% {
//     // opacity: 1;
//     transform: scale(1);
//   }
// }
